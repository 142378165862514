@import "../web/colors";

.card {

  background: $white;
  box-shadow: 2px 2px 50px -4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  .card-content {
    padding: 25px;
    .title {
      margin-bottom: 16px;
      &.is-6 {
        font-family: 'Vollkorn';
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
        line-height: 30px;
        color: $real-black;
      }
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $real-black;
      margin-bottom: 36px;
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 25px;

      min-width: 119px;
      height: 44px;

      background-color: $purple;
      border-radius: 39px;
      border: none;
      cursor: pointer;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;

      color: $white;

    }

  }
}