@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  background-image: url('./assets/alnyomat.svg');
  background-repeat: repeat-y;
  background-size: cover;
}

.container {
  &.main {
    margin-top: 165px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    &.main {
      margin-top: 82px;
    }
  }
}