@import "../web/_colors.scss";

.navbar {
  position: fixed;
  height: 165px;
  width: 100%;
  left: 0;
  top: 0;
  background: $white;

  .navbar-brand {
    height: 80px;
    padding-left: 71px;
    padding-right: 80px;

    .navbar-end {
      .select {
        &::after {
          margin-top: 0;
          margin-right: 5px;
        }
      }
    }

    .vertical-line {
      width: 36px;
      height: 0px;
      left: 252px;
      top: 19px;

      border: 1px solid $purple;
      transform: rotate(90deg);
    }

    .navbar-item {
      img {
        max-height: initial;
      }
      @media screen and (max-width: 1023px) {
        flex-direction: column;
      }
    }

    .navbar-brand-text {
      width: 173px;
      height: 24px;
      left: 275px;
      top: 25px;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $purple;
    }

    .navbar-end {
      button {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: $black;
        background: $white;
        border: 1px solid $purple;
        box-sizing: border-box;
        border-radius: 39px;
        width: 175px;
        height: 44px;
        cursor: pointer;
      }
    }

  }

  .container {
    flex-direction: column;
    padding: 0;
  }

  .navbar-menu {
    height: 85px;
    background: $white;
    box-shadow: 2px 2px 50px -4px rgba(0, 0, 0, 0.07);
    padding-left: 80px;
    padding-right: 80px;
    margin-left: -0.75rem;
    margin-right: initial !important;

    .navbar-start {
      .navbar-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #3A3A3A;

        &:after {
          content: '';
          width: 17px;
          height: 3px;
        }

        &:active, &:focus, &:hover {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          color: #3A3A3A;
          background-color: initial;

          &:after {
            content: '';
            width: 17px;
            height: 3px;
            background: #F28D55;
            border-radius: 12px;
          }
        }
      }
    }

    .navbar-end {

      align-items: center;

      button {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: $white;

        padding: 10px 25px;

        background: $purple;
        border-radius: 39px;
        border: none;
        cursor: pointer;
        
        @media screen and (max-width: 1024px) {
          display: none;
        }

        &:first-of-type {
          margin-right: 17px;

          &:before {
            content: url("./assets/headset_mic.png");
            width: 14.82px;
            height: 18px;
            position: relative;
            display: inline-block;
            top: 3px;
            right: 5px;
          }
        }

        &:nth-of-type(2) {
          &:before {
            content: url("./assets/Calendar.png");
            width: 21px;
            height: 21px;
            position: relative;
            top: 4px;
          }
        }

      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    height: initial;

    .navbar-brand {
      padding-left: initial;
      padding-right: initial;

      .navbar-burger {
        span{
          &:nth-child(2) {
            width: 8px;
          }
          &:nth-child(3) {
            width: 14px;
          }
        }
      }

    }

  }
}