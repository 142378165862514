@import "../web/colors";

.landing-page {

  .finalize-modal {
    position: fixed;
    z-index: 9999999999999;
    background-color: rgba(0,0,0,.5);
    width: 100%;
    height: 100%;
    top: 0px;
    display: none;

    .modal-bg {
      background-image: url("./assets/finalize_popup.svg");
      background-size: cover;
      width: 45%;
      height: 320px;
      background-position-x: center;
      margin: 300px auto auto;
      border-radius: 5px;
    }
  }

  .content {
    display: flex;
    flex-direction: row-reverse;
    @media screen and (max-width: 1023px) {
      flex-direction: column-reverse;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .main-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 40px;
      width: 725px;
      background: rgba(255, 255, 255, 0.7);
      /* Blur card */

      box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.07);
      backdrop-filter: blur(25px);
      -webkit-backdrop-filter: blur(25px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 5px;
      position: absolute;
      left: 80px;
      top: 50px;

      @media screen and (max-width: 1023px) {
        width: 95%;
        height: auto;
        position: initial;
        margin-left: auto;
        margin-right: auto;
        margin-top: -25px;
      }

      h1 {
        font-family: 'Vollkorn';
        font-style: normal;
        font-weight: 600;
        font-size: 44px;
        line-height: 60px;
        color: $purple;
      }

      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: $black;
      }

      button {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: $white;

        width: 321px;
        height: 44px;
        background: $purple;
        border-radius: 39px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 25px;
        border: none;
        cursor: pointer;
      }

    }
    .main-image {
      width: 752px;
      height: 454px;
      background: url("./assets/main_image-trimmy.png"), #6B74C7;
      background-size: cover;
      background-position: center;
      box-shadow: 2px 2px 50px -4px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 5px 5px;
      @media screen and (max-width: 1023px) {
        width: initial;
        height: 247px;
        background-size: cover;
      }
    }
  }

  .custom-shoes-catalogs, .content{
    padding-left: 80px;
    padding-right: 80px;
  }

  .custom-shoes-catalogs {
    @media screen and (max-width: 1023px) {
      padding-left: 0;
      padding-right: 0;
      width: 95%;
      margin: auto;
    }
  }

  .header-line {
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: $ortorange;
    position: relative;
    top: -10px;
    padding-left: 80px;
    padding-right: 80px;

    h3 {
      margin-right: 16px;
    }

    .orange-line {
      border-bottom: 1px solid $ortorange;
      height: 0;
      flex-grow: 1;
    }

  }
  .custom-shoes-process-container {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 2px -2px 50px -4px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    margin-top: 66px;
    padding-bottom: 50px;
    margin-bottom: 66.5px;

    .columns {

      padding-left: 80px;
      padding-right: 80px;
      padding-top: 67px;

      .card {
        box-shadow: none;
        border-radius: 8px;
        margin-right: 31px;
        height: 280px;

        &:last-child {
          margin-right: 0;
        }

        .card-content {
          padding-top: 20px;
          .title {
            &.is-1 {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 80px;
              color: $grey-light;
            }
          }

          p {
            color: $black;
            min-height: auto;
          }

        }
      }
    }
  }
  .shoes-parts {
    background: rgba($grey-light, 0.2);
    box-shadow: 2px -2px 50px -4px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
  .main {
    padding-left: 0;
    padding-right: 0;
  }
}