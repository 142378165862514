@import "../web/colors";

.file-upload {

  .modal-content {
    width: 844px;
  }

  .content {
    flex-direction: column;
    padding: initial;

    .header-line {
      margin-top: 26px;
      padding: 0;
      h3 {
        display: flex;
        align-items: center;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #F28D55;
        position: relative;
        margin-right: 0;
        margin-bottom: 0;
      }
    }

    .file-label {
      width: 44px;
      .file-cta {
        background-image: url("./assets/choose.svg");
        background-color: transparent;
        border: none;
        height: 44px;
        background-size: contain;
      }
    }

    .images-box {
      display: flex;
      flex-direction: row;
      max-height: 400px;
      overflow: hidden;
      margin-bottom: 20px;
      flex-flow: wrap;

      .file-upload-button {
        width: 255px;
        height: 177px;
        margin-left: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .image-box-wrapper {
        p {
          padding-top: 0;
          margin-bottom: 0 !important;
          padding-left: 9px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: $black;
          width: 255px;
          overflow-x: hidden;
        }
        .image-box {
          width: 255px;
          height: 177px;
          margin-left: 9px;
          text-align: center;
          justify-content: center;
          align-items: flex-end;
          background-size: cover;
          background-position: center;
          border-radius: 4px;
        }
      }
    }

    .columns {
      padding-bottom: 0px;
    }

    button {
      &.back-button, &.next-button {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
        height: 44px;
        border-radius: 39px;
        padding: 0;
        min-width: unset;
      }

      &.back-button {
        border: 1px solid $purple-button;
        box-sizing: border-box;
        border-radius: 39px;
        background-color: $white;
        color: $black;
        width: 97px;
        margin-right: 30px;
      }

      &.next-button {
        background: $purple-button;
        width: 244px;
        color: $white;
        border: none;
      }
    }

    .max-count-label {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: $grey-dark;
      margin-top: 30px;
    }
  }
}