@import "../web/colors";

.contact-form {
  background-color: $white;
  .header-line {
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: $ortorange;
    padding: 31px 80px;

    h3 {
      margin-right: 16px;
    }

    .orange-line {
      border-bottom: 1px solid $ortorange;
      height: 0;
      flex-grow: 1;
    }
  }

  .form {
    h2 {
      font-family: 'Vollkorn';
      font-style: normal;
      font-weight: 600;
      font-size: 19px;
      line-height: 30px;
      color: $black;
    }

    label {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $grey-dark;
    }

    h3 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: $black;
      margin-bottom: 15px;
    }

    input[type=text] {
      height: 50px;
      width: 100%;
      border: 1px solid $grey-light;
      box-sizing: border-box;
      border-radius: 5px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $grey-dark;
      padding-left: 14px;
      background-color: $white;
      &:focus-visible {
        outline: none;
      }
    }

    textarea {
      height: 100px;
      width: 100%;
      border: 1px solid $grey-light;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: $white;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $grey-dark;
      padding-left: 14px;
      resize: none;
      padding-top: 13px;
      margin-bottom: 50px;
      &:focus-visible {
        outline: none;
      }
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $black;
    }

    .checkbox-label {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $black;
      margin-left: 7.75px;
    }

    input[type=submit] {
      height: 44px;
      border-radius: 39px;
      background-color: $purple-button;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: $white;
      border:none;
      cursor: pointer;
      padding: 10px 25px;
      margin-top: 16px;
      margin-bottom: 125px;
    }

  }
}

@media screen and (max-width: 768px) {
  .contact-form {
    padding-left: 20px;
    padding-right: 20px;
  }
}