@import "../web/colors";

.insoles {
  margin: 0 80px;
  padding-bottom: 80px;

  figure {
    margin: initial;
  }

  .card {
    position: relative;
    .card-content {
      p {
        margin-bottom: 86px;
      }

      button {
        position: absolute;
        bottom: 25px;
      }
    }
  }

  .header-line {
    top: -22px;
    padding: 0;

    button {
      margin-left: 16px;
      background: $white;
      border: 1px solid $purple-button;
      box-sizing: border-box;
      border-radius: 39px;
      height: 44px;
      padding: 0 25px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: $black;
      cursor: pointer;
      &:before {
        background-image: url('./assets/reset-arrow.svg');
        background-size: 18px 15.43px;
        display: inline-block;
        width: 18px;
        height: 15.43px;
        content: "";
        margin-right: 11.5px;
      }
    }

  }

}

@media screen and (max-width: 768px) {
  .insoles {
    margin: 0 20px;
    .header-line {
      top: 0;
      padding: 20px 0;
    }
  }
}