@import "../web/colors";
.product-chooser {
  .details {
    display: flex;
    box-shadow: 2px 2px 50px -4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: $white;
    margin-left: 80px;
    margin-right: 80px;
    .images {
      width: 50%;

      figure {
        margin-left: 0;
        margin-right: 0;
      }

      li + li {
        margin-top: 0;
      }

      ol {

        list-style-position: unset;
        margin-left: 0;
        margin-top: 0;

        &:not([type]) {
          list-style-type: none;
        }
      }

      .carousel__prev {
        bottom: 10px;
        top: unset;
        left: unset;
        right: 0;
        transform: translate(-190%, -50%);
        background-color: $purple-button;
        .carousel__icon {
          display: none;
        }
        &:after {
          content: url("./assets/left-arrow.svg");
          width: 20px;
          height: 32px;
        }
      }

      .carousel__next {
        bottom: 10px;
        top: unset;
        transform: translate(-50%, -50%);
        background-color: $purple-button;
        .carousel__icon {
          display: none;
        }
        &:after {
          content: url("./assets/right-arrow.svg");
          width: 20px;
          height: 32px;
        }
      }

    }
    .text-content {
      padding: 40px;
      width: 50%;
      h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: $ortorange;
      }

      ul {
        li {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }
      }

      h2 {
        font-family: 'Vollkorn';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        color: $black;
        margin-bottom: 10px;
      }

      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: $black;
        margin-bottom: 10px;
      }

      .product-details-tags {
        .tag {
          background: rgba(115, 143, 198, 0.1);
          border: 1px solid $purple-button;
          box-sizing: border-box;
          border-radius: 39px;
          height: 34px;
          margin-bottom: 15px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: $black;
          padding: 5px 15px;

          &.disabled {
            background: rgba(115, 143, 198, 0.1);
            opacity: 0.5;
          }
        }
      }

      .icon-text {
        &.info-text {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: $black;
          flex-wrap: nowrap;
        }

        .info-icon {
          content: url("./assets/info-icon.svg");
          width: 18px;
          height: 18px;
        }
      }

    }
  }

  .product-items {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 32px;
    margin-top: 66px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 2px -2px 50px -4px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

    .header-line {
      background: unset;
      padding-top: unset;
      padding-bottom: 32px;
    }

    .product-card-item {
      img {
        padding: 25px;
      }
    }

  }

  @media screen and (max-width: 768px) {
    .details {
      flex-direction: column;
      margin-left: 10px;
      margin-right: 10px;
      .images {
        width: initial;
      }
      .text-content {
        width: initial;
      }
    }

    .product-items {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

}