@import '../web/colors';

.catalogue {
  .header-line {
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: $ortorange;
    padding: 31px 80px;

    h3 {
      margin-right: 16px;
    }

    .orange-line {
      border-bottom: 1px solid $ortorange;
      height: 0;
      flex-grow: 1;
    }

  }

  .content {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media screen and (max-width: 768px) {
    .content {
      padding-left: 0;
      padding-right: 0;
    }
  }

}