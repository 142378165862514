@import "../web/colors";

.colored-part-handle-div {
  display: flex;
  align-items: center;
  margin-top: 20px;

  label {
    max-width: 85px;
    margin-left: 10px;
    color: $black;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .colored-part-handle {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-image: url("./assets/select-part-icon.svg");
    cursor: default;
    background-repeat: no-repeat;
    background-position: center;

    &.is-selected {
      background-image: url("./assets/selected-part-icon.svg");
    }
  }
}
