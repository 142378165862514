@import "../web/colors";

.catalogue-filters-tags {
  background: $white;
  border: 1px solid $grey-light;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 25px 38px 20px 25px;
  display: block;

  h2 {
    font-family: 'Vollkorn';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 30px;
    color: $ortorange;
    padding-bottom: 25px;
  }

  .tag {
    background: rgba(115, 143, 198, 0.1);
    border: 1px solid $purple-button;
    box-sizing: border-box;
    border-radius: 39px;
    height: 34px;
    margin-bottom: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: $black;
    padding: 5px 15px;
    cursor: pointer;
  }

  .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: $black;
    outline: none;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    background: none;
    &:after {
      content: url(./assets/arrow-down.png);
      float: right;
    }

    &.active {
      &:after {
        content: url("./assets/arrow-up.png");
      }
    }

  }

  .accordionItem{
    &.open{
      .expand-content {
        display: block;
      }
      .title {
        &:after {
          content: url("./assets/arrow-up.png");
        }
      }
    }
    &.close {
      .expand-content {
        display: none;
      }
      .title {
        &:after {
          content: url(./assets/arrow-down.png);
          float: right;
        }
      }
    }
  }

  .expand-content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    transition: opacity 1s ease-out;

    ul {
      list-style-type: none;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $black;
      margin: 0 0 10px;
    }

  }

}