.customization-color-chooser {
  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: #3A3A3A;
    text-transform: capitalize;
  }
}