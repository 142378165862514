@import "../web/colors";

.color-customization {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 2px -2px 50px -4px rgba(0, 0, 0, 0.05);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: rgba(255, 255, 255, 0.1);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
  }
  //backdrop-filter: blur(20px);

  .container {
    padding-left: 80px;
    padding-right: 80px;

    .notification {
      padding: 12px 20px;
    }

    .info-icon {
      content: url("./assets/info-icon.svg");
      width: 18px;
      height: 18px;
    }

    .header-line {
      top: -22px;
      padding: 0;

      button {
        margin-left: 16px;
        background: $white;
        border: 1px solid $purple-button;
        box-sizing: border-box;
        border-radius: 39px;
        height: 44px;
        padding: 0 25px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: $black;
        cursor: pointer;
        &:before {
          background-image: url('./assets/reset-arrow.svg');
          background-size: 18px 15.43px;
          display: inline-block;
          width: 18px;
          height: 15.43px;
          content: "";
          margin-right: 11.5px;
        }
      }

    }

  }

  @media screen and (max-width: 768px) {
    .container {
      padding-left: 20px;
      padding-right: 20px;
      .header-line {
        top: 0;
        padding: 20px 0;
      }
    }
  }

  .button-container {
    .back-button {
      background: $white;

      border: 1px solid $purple-button;
      box-sizing: border-box;
      border-radius: 39px;
      margin-right: 30px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: $black;
    }
    .next-button {
      background: $purple-button;
      border-radius: 39px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: $white;
      border: none;
    }
  }

}