@import "../web/colors";

.scaffold{
  .section {
    padding: 0;
  }

  .footer {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), $purple;
    min-height: 658px;
    padding: 42px 80px 31px;

    .columns {
      margin-top: 44px;

      .column {

        &:first-child {
          margin-right: 100px;
        }

        &:nth-child(2) {
          margin-right: 95px;
        }

        &:last-child {

          input[type=text] {
            border: 1px solid $grey-light;
            box-sizing: border-box;
            border-radius: 5px;
            width: 100%;
            height: 50px;
            padding-left: 14px;
            color: $grey-dark;
            margin-bottom: 20px;

            &:focus-visible {
              outline: none;
            }

            &::placeholder {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
            }
          }

          input[type=checkbox] {
            position: relative;
            width: 20px;
            height: 17px;

            &:before {
              content: "";
              display: inline-block;
              position: absolute;
              width: 20px;
              height: 20px;
              top: 0;
              left: 0;
              border: 1.5px solid #FFFFFF;
              border-radius: 2px;
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), $purple;
            }

            &:checked {
              &:after {
                content: "";
                display: block;
                width: 8px;
                height: 17px;
                border: solid $white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(35deg);
                -ms-transform: rotate(35deg);
                transform: rotate(35deg);
                position: absolute;
                top: 0;
                left: 7px;
              }
            }

          }

          .checkbox {
            color: $white;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            display: block;
            margin-bottom: 10px;
          }

          .button {
            background-color: $ortorange;
            border-radius: 39px;
            border: none;
            padding: 25px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: $white;
            margin-top: 10px;
          }

        }

        p, a {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: $white;
          margin: 10px 0px;
          display: block;
        }

        .is-3 {
          font-family: 'Vollkorn';
          font-style: normal;
          font-weight: 600;
          font-size: 19px;
          line-height: 30px;
          color: $white;
          margin-top: 32px;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    .line {
      border: 1px solid $purple;
      border-top: none;
    }

    .copyright {
      font-family: 'Vollkorn';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: $white;
      margin-top: 27px;
    }
  }

  @media screen and (max-width: 768px) {
    .footer {
      padding: 42px 30px 31px;
      .container {
        &.is-fluid {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}