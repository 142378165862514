@import "../web/colors";

.order {
  padding-top: 50px;
  background-color: $white;

  .order-content {
    padding-left: 80px;
    padding-right: 80px;

    .header-line {
      padding-left: 0;
      padding-right: 0;

      button {
        margin-left: 16px;
        background: $white;
        border: 1px solid $purple-button;
        box-sizing: border-box;
        border-radius: 39px;
        height: 44px;
        padding: 0 25px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: $black;
        cursor: pointer;
        &:before {
          background-image: url('./assets/reset-arrow.svg');
          background-size: 18px 15.43px;
          display: inline-block;
          width: 18px;
          height: 15.43px;
          content: "";
          margin-right: 11.5px;
        }
      }

    }

  }
  .columns {
    margin: 0;
    .image-box {
      background: $white;
      border: 1px solid $grey-light;
      box-sizing: border-box;
      border-radius: 5px;
      height: 450px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .details {
      padding-left: 30px;

      h2 {
        font-family: 'Vollkorn';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        color: $black;
        margin-top: 0;
      }

      h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $ortorange;
      }

      .texts {
        padding: 0;
        h3 {
          font-family: 'Vollkorn';
          font-style: normal;
          font-weight: 600;
          font-size: 19px;
          line-height: 30px;
          color: $black;
        }

        .text {
          display: flex;
          align-items: end;
          margin-bottom: 1em;
          
          p{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $black;
            width: 50%;
            flex-shrink: 0;
          }

          label {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: $black;
          }
        }
      }

      label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $grey-dark;
      }

    }
  }

  .button-container {
    padding-bottom: 125px;
    margin-top: 75px;
    .back-button {
      background: $white;

      border: 1px solid $purple-button;
      box-sizing: border-box;
      border-radius: 39px;
      margin-right: 30px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: $black;
      cursor: pointer;
      padding: 10px 25px;
    }

    .finish-button {
      height: 44px;
      border-radius: 39px;
      background-color: $purple-button;
      color: $white;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      padding: 10px 25px;
      border: none;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 768px) {
    .order-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}