@import "../web/colors";

.size {
  padding-left: 80px;
  padding-right: 80px;
  background-color: $white;

  .header-line {
    top: -22px;
    padding: 0;

    button {
      margin-left: 16px;
      background: $white;
      border: 1px solid $purple-button;
      box-sizing: border-box;
      border-radius: 39px;
      height: 44px;
      padding: 0 25px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: $black;
      cursor: pointer;
      &:before {
        background-image: url('./assets/reset-arrow.svg');
        background-size: 18px 15.43px;
        display: inline-block;
        width: 18px;
        height: 15.43px;
        content: "";
        margin-right: 11.5px;
      }
    }

  }

  h2 {
    font-family: 'Vollkorn';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 30px;
    color: $black;
    margin-bottom: 15px;
  }

  p {
    padding-top: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: $black;
    margin-bottom: 50px !important;
  }

  .slider {
    display: flex;
    align-items: center;

    .vue3-slider {
      .handle {
        transform: scale(2);
        &.hover {
          transform: scale(2.35);
        }
      }
    }
    input[type=number] {
      width: 89px;
      height: 50px;
      border: 1px solid #D3D3D3;
      box-sizing: border-box;
      border-radius: 5px;
      margin-right: 15px;
      padding-left: 15px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $black;
    }

    .unit-wrapper {
      position: relative;
      span {
        position: absolute;
        left: 50px;
        top: 13px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $black;
      }
    }

  }

  .columns {
    padding-bottom: 89px;

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: $black;
    }
  }

  button {
    &.back-button, &.next-button {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      cursor: pointer;
      height: 44px;
      border-radius: 39px;
    }

    &.back-button {
      border: 1px solid $purple-button;
      box-sizing: border-box;
      border-radius: 39px;
      background-color: $white;
      width: 97px;
      margin-right: 30px;
    }

    &.next-button {
      background: $purple-button;
      width: 105px;
      color: $white;
      border: none;
    }
  }

  .image-upload-box {
    img {
      width: 40px;
      height: 40px;
      margin-left: 35px;
      margin-right: 34px;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: $black;
      flex-shrink: 100;
    }

    button {
      background: $white;
      border: 1px solid $purple-button;
      box-sizing: border-box;
      border-radius: 39px;
      width: 165px;
      height: 44px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: $black;
      margin-left: 51px;
      cursor: pointer;
    }

    height: 120px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.85);
    /* Primary/Purple */

    border: 1px solid $purple-button;
    box-sizing: border-box;
    border-radius: 5px;
  }
}

@media screen and (max-width: 768px) {
  .size {
    padding-left: 20px;
    padding-right: 20px;
    .header-line {
      top: 0;
      padding: 20px 0;
    }
    .image-upload-box {
      flex-direction: column;
      height: initial;

      button {
        margin-left: initial;
      }
    }
  }
}