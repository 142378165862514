@import "../web/colors";

.level {
  background: $white;
  margin-bottom: 0 !important;
}

.progress-step-circle {
  height: 20px;
  width: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./assets/completed-circle.svg");

  &.is-current {
    background-image: url("./assets/active-circle.svg");
  }

  &.is-next {
    background-image: url("./assets/circle.svg");
  }

}

.progress-step-name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: $black;

  &.is-next {
    color: $grey-dark;
  }
}